@import '~theme/variables';

.mobileItemsWrapper {
  margin-top: 1.6rem;
  transition: height 0.3s ease-in-out;
  height: 0;
  overflow: hidden;
}

.webItemsWrapper {
  margin-top: 1.6rem;
}

.item {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 1.6rem;
    fill: $color-neutral-01;
  }
}

.mobileList {
  cursor: pointer;
  .title {
    display: flex;
    justify-content: space-between;
  }
}
