@import '~theme/variables';

.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $space-xxs;

  h1 {
    margin-bottom: $space-xl;
  }
}

.offerCard {
  width: 100%;
  word-break: break-word;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(2, min-content);
  gap: $space-xs;
  padding: $space-xs;
  border: 0.1rem solid $color-neutral-04;
  box-shadow: 0rem 0.6rem 1.6rem rgba(16, 15, 13, 0.08);
  border-radius: $border-radius-03;

  svg {
    font-size: 5.6rem;
    margin: 0 auto;
  }

  .textWrapper {
    text-align: center;

    > .subtitle {
      line-height: 1.8rem;
    }
  }

  .title {
    font-size: $small-font-size;
    line-height: $h3-line-height;
  }

  @include breakpoint(sm) {
    .title {
      font-size: $h5-font-size;
    }
  }

  @include breakpoint(md) {
    padding: $space-s;

    .title {
      font-size: $h5-font-size;
    }
  }

  @include breakpoint(lg) {
    .title {
      line-height: $h2-line-height;
    }
  }
}

.highlightedOffersContainer {
  margin-bottom: $space-l;

  @include breakpoint(md) {
    margin-bottom: $space-xl;
  }
}
